export const INT_RADIX = 10;
export const TRUE_STRING = 'true';
export const FALSE_STRING = 'false';
export const ZERO_STRING = '0';
export const TOPIC_LAYER_VISITS_DEFAULT = 0;
export const FOLLOW_UP_PARENT_ELEMENTS_CHECK = 10;
export const WHITESPACE_REGEX = /\s+/g;
export const SPACE_CHARACTER = ' ';
export const CSS_SELECTOR_SEPARATOR = ',';
export const DAY = 1000 * 60 * 60 * 24;
export const HOURS = 1000 * 60 * 60;
export const MINUTES = 1000 * 60;
export const DEFAULT_LANGUAGE = 'en';
export const EMPTY_STRING = '';

export const KEY_SPEECHBUBBLE_UNREAD_NOTIFICATIONS = 'cheverpush-speechbubble-unread-notifications';
export const KEY_HAS_UNREAD_NOTIFICATIONS = 'cp-has-unread-notifications';
export const KEY_UNREAD_NOTIFICATIONS_COUNT = 'cleverpush-unread-notifications';
export const KEY_DENY_COUNT = 'cleverpush-deny-count';
export const KEY_SUBSCRIPTION_STATUS = 'cleverpush-subscription-status';
export const KEY_UNREAD_NOTIFICATIONS_CLASS = 'cleverpush-unread-notifications-count';
export const KEY_FORCED_OPT_IN_RESET_AT = 'cleverpush-forced-opt-in-reset-at';
export const CLEVERPUSH_ALERT_SHOWN = 'cleverpush-alert-shown';
export const CLEVERPUSH_BOUNCE_INITIAL_PATH = 'cleverpush-bounce-initial-path';
export const PREVENT_OPTIN_OPEN = 'dont-show-push-optin-during-shown';
export const CLEVERPUSH_DELIVERED_CONVERSION = 'cleverpush-delivered-conversion';
export const CLEVERPUSH_CONVERSION = 'cleverpush-conversion';
export const CLICK_BANNER_LOCAL_STORAGE_KEY = 'cleverpush-banner-opened';

export const PANEL_TABS = Object.freeze({
  NOTIFICATIONS: 'notifications',
  SETTINGS: 'settings'
});

export const SUBSCRIPTION_STATUS = {
  DENIED: 'denied',
  WAS_DENIED: 'was-denied',
  UNSUBSCRIBED: 'unsubscribed',
  PENDING: 'pending',
  ALLOWED: 'allowed',
};

export const BANNER_BEHAVIOUR = {
  ONCE: 'once',
  ONCE_PER_SESSION: 'oncePerSession',
  EVERY_X_SESSIONS: 'everyXSessions',
  X_TIMES_PER_DAY: 'xTimesPerDay',
  ALWAYS: 'always',
};

export const TCF_MAX_RETRY_COUNT = 30;
export const TCF_RETRY_DELAY_MS = 500;
export const TCF_VERSION = 2;

// We only allow certain methods to prevent unwanted XSS
export const POST_MESSAGE_ALLOWED_METHODS = [
  'triggerFollowUpEvent',
  'trackEvent',
  'trackConversion',
  'tagSubscription',
  'untagSubscription',
  'setAttribute',
  'incAttribute',
  'pushAttributeValue',
  'pullAttributeValue',
  'subscribe',
  'triggerOptIn',
  'unsubscribe',
];

export const MOBILE_SCROLL_POSITION_DELAY_VALUE = 50;
export const MOBILE_DELTA_VALUE = -80;
export const MOBILE_EXIT_INTENT_LOCATION_HASH = '#!/showMobileExitIntentPopup';
export const WEB_BANNER = 'webBanner';
export const WIDGET = 'widget';
export const OPT_INS = 'optIns';
export const TIME_UNITS = {
  MINUTES: 'MINUTES',
  HOUR: 'HOUR',
  DAYS: 'DAYS',
  SECONDS: 'SECONDS',
};
export const MULTIPLE_BANNER_BEHAVIOUR = {
  PER_SESSION: 'perSession',
  PER_IMPRESSION: 'perImpression'
};

export const MILLISECOND_UNIT = {
  [TIME_UNITS.MINUTES]: 60 * 1000,
  [TIME_UNITS.HOUR]: 60 * 60 * 1000,
  [TIME_UNITS.DAYS]: 24 * 60 * 60 * 1000,
  [TIME_UNITS.SECONDS]: 1000,
};

export const ADDITIONAL_CONVERSION_EXPIRATIONS = [
  {
    value: 24,
    unit: TIME_UNITS.HOUR,
  },
  {
    value: 7,
    unit: TIME_UNITS.DAYS,
  },
  {
    value: 30,
    unit: TIME_UNITS.DAYS,
  },
];

export const CLEVERPUSH_EXIT_INTENT_SHOWN = 'cleverpush-exit-intent-shown';
export const SESSION_BANNER_DISPLAY_KEY = 'cleverpush-banner-shown-current-session';

export const convertMSToMinutes = (ms) => ms / 60000;
export const convertMinutesToMS = (minutes) => minutes * 60 * 1000;

export const ADDITIONAL_CONVERSION_EXPIRATIONS_MINUTES = ADDITIONAL_CONVERSION_EXPIRATIONS.map((timeDuration) => convertMSToMinutes(timeDuration.value * MILLISECOND_UNIT[timeDuration.unit]));
export const TRACK_EVENTS = {
  CONVERSIONS: 'conversions'
};

export const OUTBRAIN_CSS_SELECTOR = '.ob-smartfeed-wrapper, .OUTBRAIN, outbrain-widget, .js-ob';
